import { AnalyticsEventParams } from '../../web/components/Shared/types';
import { IItem } from '../models';
import { Item } from '../models/ebid.types';
import { Account } from '../models/user.types';
import { api } from '../services/api';

export const trackItemView = (
  item: IItem.Item | { id: number },
  user: Account | null,
  publicUserId?: string,
) => {
  const eventParams: AnalyticsEventParams = {
    eventType: 'ITEM_UNIQUE_VIEW',
    objectName: 'item',
    rowId: item.id,
    unique: true,
  }
  if (user) {
    api.trackEvent({
      ...eventParams,
      userId: user.id,
    });
  } else if (publicUserId) {
    api.trackEvent({
      ...eventParams,
      publicUserId,
    });
  }

  api.trackEvent(
    {
      eventType: 'ITEM_VIEWS',
      objectName: 'item',
      rowId: item.id,
    },
    true,
  );
};

export const trackItemBid = (
  item: IItem.Item | { id: number },
  user: Account | { id: number } | null,
) => {
  if (user) {
    api.trackEvent({
      eventType: 'ITEM_BID',
      objectName: 'item',
      rowId: item.id,
      userId: user.id,
      unique: true,
    });
  }
};
