import { ApiResponse, PROBLEM_CODE } from 'apisauce';
import { IApp, IAuction, IEbid, IGeneral, IItem, IUser } from '../../models';

export type ResponseProblemType = PROBLEM_CODE | 'TOKEN_EXPIRED_ERROR' | 'AUTH_ERROR';

export enum ResponseProblem {
  CANCEL_ERROR = 'CANCEL_ERROR',
  TIMEOUT_ERROR = 'TIMEOUT_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
  CLIENT_ERROR = 'CLIENT_ERROR',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  TOKEN_EXPIRED_ERROR = 'TOKEN_EXPIRED_ERROR',
  AUTH_ERROR = 'AUTH_ERROR',
}

export type ErrorResponseData = any; // TODO Extend

//

export type RegisterFields = 'buyer_type' | 'username' | 'last_name' | 'gov_id' | 'email' | 'phone';

export type RegisterErrorData = {
  error: { code: string; error: string };
  // detail: { field: RegisterFields; error: string }[];
  type: string;
};

//

export type MobileAppConfigResponseData = {
  ltype: string;
  lcode: string;
  lvalue: string;
  active: boolean;
  cfg: IApp.MobileAppConfig;
};

export type MobileAppConfigResponse = ApiResponse<MobileAppConfigResponseData, ErrorResponseData>;

//

export type AuthTokenResponseData = IUser.AuthToken;

export type AuthTokenResponse = ApiResponse<AuthTokenResponseData, ErrorResponseData>;

//

export type ResetPasswordResponseData = {
  envelope: { from: string; to: string[] };
  messageId: string;
  response: string;
};

export type ResetPasswordResponse = ApiResponse<ResetPasswordResponseData, ErrorResponseData>;

//

export type CountriesResponseData = IGeneral.Lookup[];
export type CountriesResponse = ApiResponse<CountriesResponseData, ErrorResponseData>;

//TODO: Probably should extended
export type RegisterResponseData = IUser.Account & {
  uid: string;
  created_at: Date;
  updated_at: Date;
};
export type RegisterResponse = ApiResponse<RegisterResponseData, ErrorResponseData>;

export type UpdateUserResponseData = IUser.Account;
export type UpdateUserResponse = ApiResponse<UpdateUserResponseData, ErrorResponseData>;

//
export type UserPrefsResponseData = {
  id: number;
  user_id: number;
  lcode: string;
  active: number;
  created_by: string;
  updated_by: string;
  created_at: Date;
  updated_at: Date;
}[];

export type UserPrefsResponse = ApiResponse<UserPrefsResponseData, ErrorResponseData>;

export type AccountResponseData = IUser.Account;

export type AccountResponse = ApiResponse<AccountResponseData, ErrorResponseData>;

//

/**
 * **NOTE: Only use for API parsing**
 *
 * Auction response type from `/marketplace/dashboard`
 *
 * `Auction` usage across the app, `AuctionResponse` API parsing
 *
 * If they are different define flat structure here for api
 * and convert to Auction in reducer
 */
export interface AuctionResponse extends IAuction.Auction {}

export type DashboardResponseData = {
  counts?: {
    favs_ct?: number;
    prebids_ct?: number;
    moc_ct?: number;
  };
  events: AuctionResponse[];
};

export type DashboardResponse = ApiResponse<DashboardResponseData, ErrorResponseData>;

//

export type EbidActivityResponseData = { data: IItem.Flat.BidActivityFlat[] };
export type EbidActivityResponse = ApiResponse<EbidActivityResponseData, ErrorResponseData>;

// Purchases

export type PurchasesResponseData = IItem.Flat.PurchaseFlat[];
export type PurchasesV2ResponseData = IItem.Flat.PurchaseV2Flat[];

export type PurchasesResponse = ApiResponse<PurchasesResponseData, ErrorResponseData>;
export type PurchasesV2Response = ApiResponse<PurchasesV2ResponseData, ErrorResponseData>;

export type PaymentDetailsResponseData = IItem.Flat.PaymentDetailsFlat;

export type PaymentDetailsResponse = ApiResponse<PaymentDetailsResponseData, ErrorResponseData>;

// Payment

export type PaymentSubmissionResponseData = IItem.Flat.PaymentSubmissionFlat;
export type PaymentSubmissionResponse = ApiResponse<
  PaymentSubmissionResponseData,
  ErrorResponseData
>;

// Offers

export type OffersResponseData = IItem.Flat.OfferFlat[];

export type OffersResponse = ApiResponse<OffersResponseData, ErrorResponseData>;

//

export type AuctionLotsFilterOptions = {
  // [key: string]: { key: string }[];
  makes: { key: string; label: string; value: string }[];
  locations: { key: string; label: string; value: string }[];
  itemTypes: { key: string; label: string; value: string }[];
  conditions: { key: string; label: string; value: string }[];
  auctions: { key: string; label: string; value: string }[];
  models: { key: string; label: string; value: string }[];
};

/**
 * **NOTE: Only use for API parsing**
 *
 */

export type ItemsResponseData = {
  items: IItem.Flat.ItemFlat[];
  aggs: Partial<AuctionLotsFilterOptions>;
};
export type ItemsResponse = ApiResponse<ItemsResponseData, ErrorResponseData>;
export type ItemResponse = ApiResponse<IItem.Flat.ItemFlat, ErrorResponseData>;

export type EbidItemsResponseData = { data: IItem.Flat.EbidItemFlat[] };

export type AnalyticResponse = ApiResponse<void, ErrorResponseData>;

export type EbidItemsResponse = ApiResponse<EbidItemsResponseData, ErrorResponseData>;

export type AuctionDetailsResponse = ApiResponse<IAuction.Auction, ErrorResponseData>;

export type AuctionsResponse = ApiResponse<IAuction.Auction[], ErrorResponseData>;

export type AuctionFilterResponse = ApiResponse<IAuction.Auction[], ErrorResponseData>;

export type AttendeeLinkResponseData = {
  url: string;
  host: string;
  socketToken?: string;
  event: string;
  agoraToken: string;
  agoraAppId: string;
  agoraChannel: string;
};
export type AttendeeLinkResponse = ApiResponse<AttendeeLinkResponseData, ErrorResponseData>;

export type AuctionSocketTokenResponseData = string;

export type AuctionSocketTokenResponse = ApiResponse<
  AuctionSocketTokenResponseData,
  ErrorResponseData
>;

//

export type EbidAuctionTokensResponseData = { data: IEbid.EbidAuctionAuth };

export type EbidAuctionTokensResponse = ApiResponse<
  EbidAuctionTokensResponseData,
  ErrorResponseData
>;

//

export type EbidAuctionsConfigResponseData = { data: IEbid.IncrementRange[] };

export type EbidAuctionsConfigResponse = ApiResponse<
  EbidAuctionsConfigResponseData,
  ErrorResponseData
>;

//
export type LiveLotDetailsResponseData = IItem.Item;

export type LiveLotDetailsResponse = ApiResponse<LiveLotDetailsResponseData, ErrorResponseData>;

// Prebids

export type AddPrebidData = { item: ItemResponse; event: IAuction.Auction };
export type AddPrebidResponse = ApiResponse<AddPrebidData, ErrorResponseData>;

export type RemovePrebidData = unknown;
export type RemovePrebidResponse = ApiResponse<RemovePrebidData, ErrorResponseData>;

export type GetPrebidsData = Array<IItem.Flat.PrebidFlat>;
export type GetPrebidsResponse = ApiResponse<GetPrebidsData, ErrorResponseData>;

// E-bidding
export type EbidHistoryResponse = ApiResponse<
  { data: IEbid.BidHistoryResponse[]; totalResults: number; itemId: number },
  ErrorResponseData
>;

export type EbidLotBidResponseData = { message: string };

export type EbidLotBidResponse = ApiResponse<EbidLotBidResponseData, ErrorResponseData>;

export type EbidLotDetailsResponseData = { data: IItem.Item };

export type EbidLotDetailsResponse = ApiResponse<EbidLotDetailsResponseData, ErrorResponseData>;

//

export type HoldLotDetailsResponseData = IItem.Item;

export type HoldLotDetailsResponse = ApiResponse<HoldLotDetailsResponseData, ErrorResponseData>;

//

export type WinningItemsResponseData = { data: Array<IItem.Item> };

export type WinningItemsResponse = ApiResponse<WinningItemsResponseData, ErrorResponseData>;

//
export type RecommendationsResponseData = IItem.Flat.RecommendationFlat[];

export type RecommendationsResponse = ApiResponse<RecommendationsResponseData, ErrorResponseData>;

//

export type SearchAutocompleteResponseData = string[];
export type SearchAutocompleteResponse = ApiResponse<
  SearchAutocompleteResponseData,
  ErrorResponseData
>;

export type QuerySuggestionDocuments = {
  suggestion: string;
};

export type QuerySuggestionResponseData = {
  results: {
    documents: QuerySuggestionDocuments[];
  };
};
export type QuerySuggestionResponse = ApiResponse<QuerySuggestionResponseData, ErrorResponseData>;

//

export type SearchResultResponseData = {
  items: IItem.Item[];
  aggs: Partial<AuctionLotsFilterOptions>;
};
export type SearchResultResponse = ApiResponse<SearchResultResponseData, ErrorResponseData>;
//

export type UploadFileData = {
  id: number;
  table_ref: string;
  row_id: number;
  name: string;
  type: string;
  content_type: string;
  location: string;
  content_length: number;
  file_extension: null | string;
  file_hash: null | string;
  description: null | string;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  active: number;
};

export type UploadFileResponse = ApiResponse<UploadFileData, ErrorResponseData>;
//

export type GetMolPayConfigDataJSON = string;

export type GetMolPayConfigData = {
  mp_username: string;
  mp_password: string;
  mp_merchant_ID: string;
  mp_app_name: string;
  mp_verification_key: string;
  mp_currency: string;
  mp_country: string;
  mp_channel_editing: boolean;
  mp_allowed_channels: Array<string>;
  mp_amount: number;
  mp_order_ID: string;
};

export type GetMolPayConfigResponse = ApiResponse<GetMolPayConfigDataJSON, ErrorResponseData>;

export type MolPayResponse = {
  amount: string;
  app_code: string;
  channel: string;
  chksum: string;
  err_desc: string;
  mp_secured_verified: boolean;
  msgType: string;
  order_id: string;
  pInstruction: number;
  paydate: number;
  status_code: string;
  txn_ID: string;
  error_code: string;
  error_message: string;
  Error?: string;
};

// Favorites

export type SetFavoritesResponseData = {
  item: IItem.Flat.FavoriteFlat;
  event?: IAuction.Auction;
  dashboard: {
    favsCt: number;
    prebidsCt: number;
    purchases: number;
    offers: number;
    mocCt: number;
  };
};
export type SetFavoritesResponse = ApiResponse<SetFavoritesResponseData, ErrorResponseData>;

export type GetFavoritesAllResponseData = {
  id: number;
  itemId: number;
  favorite: number;
}[];
export type GetFavoritesAllResponse = ApiResponse<GetFavoritesAllResponseData, ErrorResponseData>;

export type GetFavoritesResponseData = IItem.Flat.FavoriteFlat[];
export type GetFavoritesResponse = ApiResponse<GetFavoritesResponseData, ErrorResponseData>;

export type GetFavoritesIdsData = {
  itemId: number;
}[];
export type GetFavoritesIdsResponse = ApiResponse<GetFavoritesIdsData, ErrorResponseData>;

// FPX
export type FPXBankItem = {
  id: number;
  code: string;
  name: string;
  displayName: string;
  available: boolean
}

export type FPXBanksResponse = {
  data: FPXBankItem[];
}

export type FPXFormSignature = {
  signature: string;
  length: number;
}

export type FPXOrderResponseData = {
  orderId: string;
  externalOrderId: string;
  userId: number;
  auctionId: number;
  amount: number;
}

// App Search

export type AppSearchResponseData = {
  facets: any;
  meta: any;
  results: SearchItemResponse[];
};
export type AppSearchResponse = ApiResponse<AppSearchResponseData, ErrorResponseData>;

type Raw<T> = {
  raw: T;
};

type NullableRaw<T> = {
  raw: T | null;
};

export type SearchItemResponse = {
  auctionRemarks: NullableRaw<string>;
  color: NullableRaw<string>;
  conditionType: Raw<IItem.ConditionType>;
  eventCategory: Raw<IGeneral.EventCategory>;
  crIncidentType: Raw<IItem.Vehicle.IncidentType>;
  createdAt: NullableRaw<string>;
  depositAmt: Raw<number>;
  description: NullableRaw<string>;
  eventEnd: NullableRaw<string>;
  eventStart: NullableRaw<string>;
  eventType: Raw<IAuction.AuctionTypes>;
  id: Raw<number>;
  imagePrefix: NullableRaw<string>;
  importedTitle: NullableRaw<string>;
  inspectionRemarks: NullableRaw<string>;
  inspectionTitle: NullableRaw<string>;
  itemType: Raw<IItem.Type>;
  location: NullableRaw<string>;
  lotNum: NullableRaw<string>;
  make: NullableRaw<string>;
  meta: { id: string; engine: string; score: number };
  model: NullableRaw<string>;
  name: Raw<string>;
  objectIds: NullableRaw<string>;
  odoUnits: NullableRaw<IItem.Vehicle.OdoUnit>;
  odometer: NullableRaw<number>;
  personalEffects: NullableRaw<string>;
  saleEventId: Raw<number>;
  sourceType: Raw<string>;
  stockNum: Raw<string>;
  trim: NullableRaw<string>;
  updatedAt: Raw<string>;
  vin: NullableRaw<string>;
  year: NullableRaw<string>;
};

export type ContactUsResponse = ApiResponse<any, ErrorResponseData>;
export type SubscribeResponse = ApiResponse<any, ErrorResponseData>;

export type AuctionBadgeResponse = ApiResponse<
  {
    id: number;
    saleEventId: number;
    badge: number;
    userId: number;
    createdBy: number;
    updatedBy: number;
    createdAt: string;
    updatedAt: string;
    active: boolean;
  },
  ErrorResponseData
>;
