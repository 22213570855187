import { Task } from '@redux-saga/types';
import i18n from 'i18next';
import _ from 'lodash';
import { eventChannel } from 'redux-saga';
import {
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import io, { Socket } from 'socket.io-client';
import Config from '../config';
import { IApp, IBidding, IEbid, IItem, IUser } from '../models';
import { SOCKET_MESSAGE_TYPES, SocketUserBadgeAction } from '../models/ebid.types';
import {
  appActions,
  dashboardActions,
  itemActions,
  liveAuctionActions,
  userActions,
} from '../redux/actions';
import { ebidActions } from '../redux/ebid-auction/slice';
import { calculator } from '../redux/ebid-auction/utils';
import { composeItemFromFlatResponse } from '../redux/item/utils';
import {
  ApplicationSelectors,
  DashboardSelectors,
  EbidAuctionSelectors,
  ItemSelectors,
  LiveAuctionSelectors,
  UserSelectors,
} from '../redux/selectors';
import { api } from '../services/api';
import { EbidAddAutobidRequestBody } from '../services/api/request.types';
import {
  EbidActivityResponse,
  EbidAuctionsConfigResponse,
  EbidHistoryResponse,
  EbidItemsResponse,
  EbidLotBidResponse,
} from '../services/api/response.types';
import { getBiddingStatus, parseCreditFormula } from '../utils/helpers';
import { allowedSendingBidDuration } from '../utils/constants';
import { trackItemBid } from '../utils/analytics';

function createSocketClient(url: string, token: string) {
  return io.connect(url, {
    query: { token },
    transports: ['websocket'],
    secure: true,
  });
}

function createEventChannel(
  client: typeof Socket,
  _account: IUser.Account,
  socketPingStart: IEbid.SocketPingStart,
) {
  return eventChannel((emit) => {
    client.on('disconnect', (error: any) => {
      emit(ebidActions.onConnectionStatus({ connected: false, client: 'disconnect', error }));
      emit(ebidActions.setSocketPingId(0));
    });

    client.on('error', (error: any) => {
      emit(ebidActions.onConnectionStatus({ connected: false, client: 'error', error }));
    });

    const failConnections = [
      'reconnecting',
      'reconnect_error',
      'reconnect_failed',
      'reconnect_attempt',
      'connect_timeout',
      'connect_error',
    ];
    failConnections.forEach((failure: string) => {
      client.on(failure, (error: any) => {
        emit(
          ebidActions.onConnectionStatus({
            connected: false,
            client: failure,
            error: error?.message,
          }),
        );
      });
    });

    client.on('connect', () => {
      emit(
        ebidActions.onConnectionStatus({
          connected: true,
          client: 'connect',
          error: null,
          retries: 0,
          attempt: 0,
        }),
      );
      emit(ebidActions.onSocketConnected());
    });

    client.on('rtcPong', (data: any) => {
      let rtt: IEbid.SocketLatency = +data.pongAt - +data.pingAt;
      emit(ebidActions.setSocketLatency(rtt));
    });

    client.on('message', (event: IEbid.SocketMessage) => {
      _logger.info('saga/ebid.createEventChannel : onSocketMessage', { event });
      const msgType = event?.msgType || event?.type;

      if (msgType) {
        emit({
          type: msgType,
          event,
        });
      }
    });

    return () => {
      _logger.info('saga/ebid.createEventChannel close removeAllListeners');
      client.close();
      client.removeAllListeners();
    };
  });
}

function* onSocketMessageReceived(socket: typeof Socket, action: IEbid.SocketAction) {
  let { itemId } = action.event;
  itemId = Number(itemId);
  const account: ReturnType<typeof UserSelectors.account> = yield select(UserSelectors.account);

  const items: ReturnType<typeof ItemSelectors.items> = yield select(ItemSelectors.items);
  const item = items[itemId];

  // Proactively update block/unblock in state
  if (action.type === IEbid.SOCKET_MESSAGE_TYPES.USER_BID && account && !!item) {
    const {
      auctionStatus,
      autobidActive,
      bidCount,
      currentBid,
      endTime,
      lastBid,
      maxAutobid,
      nextBid,
      saleEventId,
      startTime,
      statusCode,
      winnerId,
    } = action.event;

    const _item = _.cloneDeep(item);

    _item.bidding = {
      ..._item.bidding,
      winnerId: winnerId ? +winnerId : undefined,
      isWinning: !!(lastBid > 0 && winnerId && +winnerId === account.id),
      isRunning: auctionStatus === 'RUNNING',
      isOutbid: !!(lastBid > 0 && winnerId && +winnerId !== account.id),
    };

    const status = getBiddingStatus(_item);

    const isFirstBidRejected = autobidActive === false && statusCode === 100 && lastBid === 0;
    let rejectedBidAmount;
    if (isFirstBidRejected) {
      rejectedBidAmount = _item?.bidding?.rejectedBid?.rejectedBidAmount
        ? _item?.bidding?.rejectedBid?.rejectedBidAmount
        : currentBid;
    } else {
      rejectedBidAmount = null;
    }

    const bidding: IBidding.Bidding = {
      winnerId: winnerId ? +winnerId : undefined,
      auctionStatus,
      bidsCount: bidCount,
      currentBidAmount: currentBid,
      startTime,
      endTime,
      nextBidAmount: nextBid,
      maxBidAmount: maxAutobid, // If the user has not set maxAutobid, it comes in with the same value as lastBid
      lastBidAmount: lastBid,
      isMaxBidActive: autobidActive,
      status,
      saleEventId,
      type: 'EBID',
      ...setComputedValues(action.event),
      ...setComputedStatuses(status),
      sendingState: {
        isSending: false,
        sendingSince: 0,
        hasExceededDuration: false,
      },
      rejectedBid: {
        isFirstBidRejected: isFirstBidRejected,
        rejectedBidAmount: rejectedBidAmount,
      },
    };

    const biddings: IItem.Bidding[] = [{ itemId: +itemId, bidding }];

    yield put(itemActions.setBiddings(biddings));

    // * Disabled deposit block/unblock logic when the user submit bid in ebid or live due to complication in delivery and handling
    // Refer AI-4513 for upcoming implementation
    // let blockedItems: { liveItems: []; ebidItems: [] } = yield select(UserSelectors.blockedItems);
    // let unblockedItems: { liveItems: []; ebidItems: [] } = yield select(
    //   UserSelectors.unblockedItems,
    // );
    // const accountState = { ...account };
    // const blocked = blockedItems?.ebidItems.includes(itemId);
    // const unblocked = unblockedItems?.ebidItems.includes(itemId);

    // const isWinning = +winnerId === account.id;
    // const itemDeposit = item?.depositAmt;
    // if (isWinning && !blocked) {
    //   if (account.availableBalance >= itemDeposit) {
    //     // proactively deduct from state before receive update from user_balance
    //     accountState.availableBalance = account.availableBalance - itemDeposit;
    //     accountState.blockedAmount = account.blockedAmount + itemDeposit;
    //   }
    //   yield put(
    //     userActions.updateBlockItem({
    //       type: 'blocked',
    //       biddingType: IBidding.TYPES.EBID,
    //       itemId,
    //     }),
    //   );
    // } else if (!unblocked) {
    //   accountState.availableBalance = account.availableBalance + itemDeposit;
    //   accountState.blockedAmount = account.blockedAmount - itemDeposit;
    //   yield put(
    //     userActions.updateBlockItem({
    //       type: 'unblocked',
    //       biddingType: IBidding.TYPES.EBID,
    //       itemId,
    //     }),
    //   );
    // }
    // yield put(userActions.getUserAccountSuccess(accountState));
  }

  if (action.type === IEbid.SOCKET_MESSAGE_TYPES.ITEM_BID) {
    const { winnerId, currentBid, _id, bidCount, nextBid, startTime, endTime, auctionStatus } =
      action.event;

    const bidding: IBidding.Bidding = {
      winnerId: winnerId ? +winnerId : undefined,
      auctionStatus,
      bidsCount: bidCount,
      currentBidAmount: currentBid,
      startTime,
      endTime,
      nextBidAmount: nextBid,
    };

    const biddings: IItem.Bidding[] = [{ itemId: +itemId, bidding }];

    yield put(itemActions.setBiddings(biddings));

    const bidHistoryMap: IEbid.HistoryMap = yield select(EbidAuctionSelectors.history);
    const latestBid = bidHistoryMap?.[+itemId] && bidHistoryMap?.[+itemId][0];

    if (latestBid) {
      const ranges: IEbid.IncrementRange[] = yield select(EbidAuctionSelectors.increments);
      const previousBidAmt = calculator(ranges).decrease(currentBid);
      const isMissingBidInBetween = latestBid.amtAccepted !== previousBidAmt;

      if (isMissingBidInBetween) {
        yield put(ebidActions.getBidHistory(itemId));
        return;
      }
    }

    const bidHistory = {
      itemId: +itemId,
      amtAccepted: currentBid,
      timestamp: new Date().valueOf(),
      id: _id,
      clientUserId: winnerId,
    };
    yield put(ebidActions.setBidHistory(bidHistory));
  }

  yield put(ebidActions.updateCounts());
}

function* onLiveEventsRelayReceived(socket: typeof Socket, action: any) {
  const { message } = action.event;
  if (message.type === 'EventStats') {
    const stats = message?.doc?.Stats;
    const currentBidBadge = stats?.CurrentBidBadge;
    const currentBidType = stats?.CurrentBidType;
    const itemSettings = stats?.ItemSettings;
    const creditFormula = itemSettings?.CreditFormula;

    const bidder: ReturnType<typeof LiveAuctionSelectors.bidder> = yield select(
      LiveAuctionSelectors.bidder,
    );

    const bidderBadge = bidder && bidder.BidderBadge;

    if (!bidderBadge) {
      const user: ReturnType<typeof UserSelectors.account> = yield select(UserSelectors.account);
      const liveSaleEventId: ReturnType<typeof LiveAuctionSelectors.saleEventId> = yield select(
        LiveAuctionSelectors.saleEventId,
      );

      if (user?.id && liveSaleEventId) {
        socket.emit('_request', {
          msgType: 'user_badge',
          user_id: user.id,
          sale_event_id: liveSaleEventId,
        });
      }

      yield put(
        liveAuctionActions.statusUpdated({
          ItemSettings: itemSettings,
          CurrentBidType: currentBidType,
        }),
      );
    } else {
      const isLiveWinning = currentBidBadge && currentBidBadge === bidderBadge;

      const liveDeposit = parseCreditFormula(creditFormula);

      yield put(liveAuctionActions.setLiveDeposit(isLiveWinning ? liveDeposit : 0));

      if (currentBidType === IItem.STATUS.SOLD) {
        yield put(userActions.checkBalance('LIVE'));
      }
    }
  }
}

function* onUserBadgeReceived(socket: typeof Socket, action: IEbid.SocketAction) {
  const { user_id, badge, sale_event_id } = (action as SocketUserBadgeAction).event;

  const user: ReturnType<typeof UserSelectors.account> = yield select(UserSelectors.account);
  const runningLiveAuction: ReturnType<typeof DashboardSelectors.runningLiveAuction> = yield select(
    DashboardSelectors.runningLiveAuction,
  );

  if (
    user?.id &&
    runningLiveAuction?.id &&
    user.id === user_id &&
    runningLiveAuction.id === sale_event_id
  ) {
    const currentBidBadge = `${badge}`;

    const bidder: ReturnType<typeof LiveAuctionSelectors.bidder> = yield select(
      LiveAuctionSelectors.bidder,
    );
    const auctionStatus: ReturnType<typeof LiveAuctionSelectors.auctionStatus> = yield select(
      LiveAuctionSelectors.auctionStatus,
    );
    const creditFormula = auctionStatus?.ItemSettings?.CreditFormula;

    if (creditFormula) {
      const bidderBadge = bidder && bidder.BidderBadge;

      const isLiveWinning = currentBidBadge && currentBidBadge === bidderBadge;

      const liveDeposit = parseCreditFormula(creditFormula);

      yield put(liveAuctionActions.setLiveDeposit(isLiveWinning ? liveDeposit : 0));

      if (auctionStatus?.CurrentBidType === IItem.STATUS.SOLD) {
        yield put(userActions.checkBalance('LIVE'));
      }
    }

    yield put(liveAuctionActions.bidderUpdated({ BidderBadge: `${badge}` }));
  }
}

function* onDependencyPresenceUpdate(_: typeof Socket, action: IEbid.SocketAction) {
  const { client, state } = action.event;
  _logger.info('saga/ebid.onDependencyPresence', {
    client,
    state,
  });

  const dependencyClients = Object.values(IEbid.DEPENDENCY_CLIENTS);
  const isDependencyClient = dependencyClients.includes(client);

  if (isDependencyClient && state === IEbid.DEPENDENCY_STATES.ONLINE) {
    yield put(ebidActions.resetSendingState());
  }
}

function* ebidAuctionSocketSaga(client: typeof Socket) {
  yield takeEvery(ebidActions.onSocketConnected.type, onSocketConnected, client);
  yield takeEvery(itemActions.setFavoriteSuccess.type, onItemFavorite, client);
  yield takeEvery(ebidActions.pingSocket.type, pingSocket, client);
  yield takeLatest(ebidActions.subscribeEventBid.type, subscribeEventBid, client);
  yield takeLatest(ebidActions.subscribeItemBid.type, subscribeItemBid, client);
  yield takeLatest(ebidActions.setAutobid.type, setAutobid, client);
  yield takeLatest(IEbid.SOCKET_MESSAGE_TYPES.USER_BALANCE, onUserBalanceV2, client);
  yield takeLatest(IEbid.SOCKET_MESSAGE_TYPES.USER_FAVORITE, onUserFavorite, client);
  yield takeLatest(IEbid.SOCKET_MESSAGE_TYPES.USER_PREBID, onUserPrebid, client);
  yield takeLatest(IEbid.SOCKET_MESSAGE_TYPES.SALE_STATUS, onSaleStatus, client);
  yield takeLatest(IEbid.SOCKET_MESSAGE_TYPES.ITEM_BID, onSocketMessageReceived, client);
  yield takeLatest(IEbid.SOCKET_MESSAGE_TYPES.USER_BID, onSocketMessageReceived, client);
  yield takeLatest(IEbid.SOCKET_MESSAGE_TYPES.EVENT_BID, onSocketMessageReceived, client);
  yield takeEvery(IEbid.SOCKET_MESSAGE_TYPES.USER_BADGE, onUserBadgeReceived, client);
  yield takeEvery(IEbid.SOCKET_MESSAGE_TYPES.SRM, onLiveEventsRelayReceived, client);
  yield takeEvery(IEbid.SOCKET_MESSAGE_TYPES.PRESENCE, onDependencyPresenceUpdate, client);
  yield takeLatest(ebidActions.relayLiveEventStats.type, relayLiveEventStats, client);
}

function* watchEbidAuctionSocket() {
  const account: IUser.Account = yield select(UserSelectors.account);
  const socketPingStart: IEbid.SocketPingStart = yield select(EbidAuctionSelectors.socketPingStart);
  yield put(itemActions.getBidActivity());

  const client = createSocketClient(account.rtc?.url, account?.rtc.token);
  const channel = createEventChannel(client, account, socketPingStart);
  const saga: Task = yield fork(ebidAuctionSocketSaga, client);
  _logger.info('saga/ebid.watchEbidAuctionSocket', { account });
  try {
    while (true) {
      yield put(yield take(channel));
    }
  } finally {
    yield cancel(saga);
    channel.close();
  }
}

function* watchEbidAuction() {
  yield race({
    socket: call(watchEbidAuctionSocket),
    cancel: take(ebidActions.watchSocketCancel.type),
  });
}

function* onSocketConnected(client: typeof Socket) {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (account?.id) {
    subscribe(IEbid.SOCKET_MESSAGE_TYPES.USER_BID, account.id, client);
    subscribe(IEbid.SOCKET_MESSAGE_TYPES.USER_BALANCE, account.id, client);
    subscribe(IEbid.SOCKET_MESSAGE_TYPES.USER_FAVORITE, account.id, client);
    subscribe(IEbid.SOCKET_MESSAGE_TYPES.USER_PREBID, account.id, client);
    subscribe(IEbid.SOCKET_MESSAGE_TYPES.SALE_STATUS, account.id, client);
    subscribe(IEbid.SOCKET_MESSAGE_TYPES.USER_BADGE, account.id, client);

    client.emit('subscribe', { domain: 'live', entity: 'events', entityId: 'relay' });
  }

  const favoritesIds: ReturnType<typeof ItemSelectors.favoritesIds> = yield select(
    ItemSelectors.favoritesIds,
  );
  // Subscribe favorited item to item_bid
  favoritesIds.forEach((id) => {
    subscribe(IEbid.SOCKET_MESSAGE_TYPES.ITEM_BID, id, client);
  });

  const ebidItems: ReturnType<typeof ItemSelectors.items> = yield select(ItemSelectors.ebidItems);
  // Subscribe Ebid item to item_bid
  Object.values(ebidItems).forEach((item) => {
    const itemId = item.id;
    subscribe(IEbid.SOCKET_MESSAGE_TYPES.ITEM_BID, itemId, client);
  });

  _logger.info('saga/ebid.onSocketConnected', { account, socketId: client.id });
  yield put(ebidActions.setSocketId(client.id));
  yield put(ebidActions.resetSendingState());
}

function* pingSocket(client: typeof Socket) {
  const pingAt: IEbid.SocketPingStart = Date.now();
  yield put(ebidActions.setSocketPingStart(pingAt));
  client.emit('rtcPing', pingAt);
}

function subscribe(entity: IEbid.SOCKET_MESSAGE_TYPES, entityId: number, client: typeof Socket) {
  const event = {
    domain: 'ebidding',
    entity,
    entityId,
  };
  _logger.info('saga/ebid.subscribe', event);
  client.emit('subscribe', event);
}

function unsubscribe(entity: IEbid.SOCKET_MESSAGE_TYPES, entityId: number, client: typeof Socket) {
  const event = {
    domain: 'ebidding',
    entity,
    entityId,
  };
  _logger.info('saga/ebid.unsubscribe', event);
  client.emit('unsubscribe', event);
}

function* subscribeEventBid(
  client: typeof Socket,
  action: ReturnType<typeof ebidActions.subscribeEventBid>,
) {
  const { saleEventId } = action.payload;
  _logger.info('saga/ebid.subscribeEventBid', { saleEventId });
  subscribe(IEbid.SOCKET_MESSAGE_TYPES.EVENT_BID, saleEventId, client);
}

function* subscribeItemBid(
  client: typeof Socket,
  action: ReturnType<typeof ebidActions.subscribeItemBid>,
) {
  const { itemId } = action.payload;
  _logger.info('saga/ebid.subscribeItemBid', { itemId });
  subscribe(IEbid.SOCKET_MESSAGE_TYPES.ITEM_BID, itemId, client);
}

function* onItemFavorite(
  client: typeof Socket,
  action: ReturnType<typeof itemActions.setFavoriteSuccess>,
) {
  const favoriteItem = action.payload.item;
  _logger.info('saga/ebid.onItemFavorite', { favoriteItem });

  if (favoriteItem.favorite) {
    subscribe(IEbid.SOCKET_MESSAGE_TYPES.ITEM_BID, favoriteItem.id, client);
  } else {
    unsubscribe(IEbid.SOCKET_MESSAGE_TYPES.ITEM_BID, favoriteItem.id, client);
  }
}

// TODO: Move to RTC saga
function* onUserBalanceV2(_: typeof Socket, action: IEbid.SocketUserBalanceAction) {
  const {
    amount: depositBalance,
    blocked_amount: blockedAmount,
    display_amount: availableBalance,
  } = action.event;
  _logger.info('saga/ebid.onUserBalanceV2', {
    depositBalance,
    blockedAmount,
    availableBalance,
  });

  const account: IUser.Account = yield select(UserSelectors.account);

  if (account) {
    const updatedAccount = {
      ...account,
      availableBalance: +availableBalance,
      blockedAmount: +blockedAmount,
      depositBalance: +depositBalance,
    };
    // TODO: Create userActions.updateUserAccount instead
    yield put(userActions.getUserAccountSuccess(updatedAccount));
  }
}

// TODO: Move to RTC saga
function* onUserFavorite(_: typeof Socket, action: IEbid.SocketUserFavoriteAction) {
  _logger.info('saga/ebid.onUserFavorite', action.event);
  const { item_id: itemId, favorite, notes, sale_event_id } = action.event;
  const payload: IItem.Favorite = {
    itemId,
    favorite,
    notes,
  };
  yield put(itemActions.updateFavorite(payload));
  if (favorite) {
    yield put(dashboardActions.increaseFavoriteCount({ saleEventId: sale_event_id }));
  } else {
    yield put(dashboardActions.decreaseFavoriteCount({ saleEventId: sale_event_id }));
  }
}

// TODO: Move to RTC saga
function* onUserPrebid(_: typeof Socket, action: IEbid.SocketUserPrebidAction) {
  _logger.info('saga/ebid.onUserPrebid', action.event);
  const { item_id: itemId, prebid_id: id, prebid_amount: amount, sale_event_id } = action.event;
  const items: ReturnType<typeof ItemSelectors.items> = yield select(ItemSelectors.items);
  const prebidItems: IItem.Item[] = Object.values(items).filter((item) => item && item.prebid);
  const prebidIds = prebidItems.map((item) => item.id);

  if (!amount) {
    yield put(itemActions.removePrebidSuccess(itemId));
    yield put(dashboardActions.decreasePrebidCount({ saleEventId: sale_event_id }));
    return;
  }
  if (!prebidIds.includes(itemId)) {
    yield put(dashboardActions.increasePrebidCount({ saleEventId: sale_event_id }));
  }

  const payload: IItem.Prebid = {
    id,
    itemId,
    amount,
  };
  yield put(itemActions.updatePrebid(payload));
}

function* onSaleStatus(socket: typeof Socket, action: IEbid.SocketSaleStatusAction) {
  _logger.info('saga/ebid.onSaleStatus', action);
  const { status } = action.event;

  if (status === IItem.STATUS.SOLD) {
    yield put(userActions.checkBalance('EBID'));
  }
}

function* getIncrementConfig() {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account?.id) {
    // avoid 403 calls if user is not logged in
    return;
  }
  const response: EbidAuctionsConfigResponse = yield call(api.getEbidConfig);

  if (response.ok && response.data) {
    yield put(ebidActions.getIncrementConfigSuccess(response.data.data));
  } else {
    yield put(ebidActions.getIncrementConfigFailure('getEbidAuctionsConfig error'));
  }
}

// TODO: Remove if not in use
// export function* getEbidItem(action: GetEbidItemAction) {
//   const { params } = action;

//   const response: EbidLotDetailsResponse = yield call(api.getEbidItemById, params);

//   if (response.ok && response.data) {
//     yield put(ebidAuctionActions.getEbidItemSuccess(params.itemId, response.data.data));
//   } else {
//     yield put(ebidAuctionActions.getEbidItemFailure('getEbidItem error'));
//   }
// }

function* getEbidItems(action: ReturnType<typeof itemActions.getEbidItems>) {
  const itemIds = action.payload;
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account?.id) {
    // avoid 403 calls if user is not logged in
    _logger.warn('saga/ebid.getItems without logged in?', { itemIds });
    return;
  }

  const response: EbidItemsResponse = yield call(api.getEbidItems, { id: itemIds });

  if (response.ok && response.data) {
    const items = response.data.data;
    _logger.info('saga/ebid.getItems', { itemIds, items });
    yield put(itemActions.getEbidItemsSuccess(items));

    const biddings: IItem.Bidding[] = [];
    const offers: { itemId: number; offer: Partial<IItem.Offer> }[] = [];

    if (items?.length > 0) {
      Object.values(items).forEach((item: IItem.Flat.EbidItemFlat) => {
        const itemId = +item.id;
        const composedItem = composeItemFromFlatResponse(item);
        const hasBids = item.lastBid > 0;
        const isOffer = item.saleStatus === 'OFFER';
        const isEnded = item.auctionStatus === 'ENDED';
        const isUserWinner = !!(item.winnerId && +item.winnerId === account.id);
        const isSold = isEnded && item.saleStatus === 'SOLD';

        composedItem.bidding = {
          winnerId: item.winnerId ? +item.winnerId : undefined,
          isRunning: item.auctionStatus === 'RUNNING', // it doesn't get reflected upon timer ticks
          isOutbid: hasBids && !isUserWinner,
          // TODO: To add 'lastBid' field returning from eBidding endpoint, currently it doesn't exist after auction ends
          isWinning: (hasBids && isUserWinner) || (isEnded && isUserWinner),
          isOffer,
        };

        const status = getBiddingStatus(composedItem);

        const bidding: IBidding.Bidding = {
          status,
          auctionStatus: item.auctionStatus,
          type: IBidding.TYPES.EBID,
          bidsCount: item.bidCount,
          currentBidAmount: item.currentBidAmt,
          nextBidAmount: item.nextBidAmt,
          lastBidAmount: item.lastBid,
          maxBidAmount: item.maxAutobid,
          isMaxBidActive: item.autobidActive,
          startTime: item.startTime,
          endTime: item.endTime,
          winnerId: item.winnerId ? +item.winnerId : undefined,
          isOfferWinner: isOffer && isUserWinner,
          isSold,
          ...setComputedValues(item),
          ...setComputedStatuses(status),
        };
        biddings.push({ itemId, bidding });

        if (item.saleStatus === 'OFFER') {
          offers.push({
            itemId,
            offer: {
              offerAmount: item.currentBidAmt,
              saleEventId: item.eventId,
              saleStatus: item.saleStatus,
            },
          });
        }
      });
    }

    yield put(itemActions.setOffers(offers));
    yield put(itemActions.setBiddings(biddings));
    yield put(ebidActions.updateCounts());
  } else {
    yield put(itemActions.getEbidItemsFailure('getItems error'));
  }
}

function* getBidHistory(action: ReturnType<typeof ebidActions.getBidHistory>) {
  const itemId = action.payload;
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account?.id) {
    // avoid 403 calls if user is not logged in
    _logger.warn('saga/ebid.getBidHistory without logged in?', { itemId });
    return;
  }

  const response: EbidHistoryResponse = yield call(api.getEbidHistoryByItem, itemId);

  _logger.info('saga/ebid.getBidHistory', { itemId });

  if (response.ok && response.data) {
    const bidHistory = response.data;
    // Require itemId to map bidsHistory and totalBids state
    bidHistory.itemId = itemId;
    _logger.info('saga/ebid.getBidHistory', { bidHistory });
    yield put(ebidActions.getBidHistorySuccess(bidHistory));
  } else {
    yield put(ebidActions.getBidHistoryFailure('getBidHistory error'));
  }
}

function* setAutobid(client: typeof Socket, action: ReturnType<typeof ebidActions.setAutobid>) {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account) {
    // avoid 403 calls if user is not logged in
    _logger.warn('saga/ebid.setAutobid without logged in?');
    return;
  }

  const status: IUser.AccountStatus = yield select(UserSelectors.accountStatus);

  if (status === 'PENDING') {
    yield put(
      appActions.showAlert({
        alertType: 'alert',
        title: 'Sorry!',
        message: i18n.t('infos:your_account_has_not'),
      }),
    );
    return;
  }

  const { itemId, userId, bidAmount, eventId } = action.payload;

  const env: IApp.Environment = yield select(ApplicationSelectors.environment);
  const platform: IApp.Platform = yield select(ApplicationSelectors.platform);
  const socketId: IEbid.SocketId = yield select(EbidAuctionSelectors.socketId);

  _logger.info('setAutobid', {
    env,
    platform,
    socketId,
    PLACE_BID_VIA_RTC: Config.PLACE_BID_VIA_RTC,
    payload: action.payload,
  });

  const items: ReturnType<typeof ItemSelectors.items> = yield select(ItemSelectors.items);
  const item = _.cloneDeep(items[Number(itemId)]);
  if (item?.bidding) {
    item.bidding.sendingState = {
      isSending: true,
      sendingSince: Date.now(),
      hasExceededDuration: false,
    };
    _logger.info('Update sending state', item?.bidding.sendingState);

    item.bidding.rejectedBid = {
      isFirstBidRejected: false,
      rejectedBidAmount: null,
    };
    yield put(itemActions.setBidding(item.id, item.bidding));
  }

  const params: EbidAddAutobidRequestBody = {
    clientItemId: itemId.toString(),
    clientUserId: userId.toString(),
    amtPlaced: +bidAmount,
    eventId: eventId?.toString(),
  };

  yield put(
    appActions.captureFirebaseAnalytics('set_autobid', {
      env,
      platform,
      socketId,
      eventId: eventId,
      itemId: itemId,
      bidAmount: +bidAmount,
      userEmail: account?.email,
      userId: account?.id,
    }),
  );

  if (Config.PLACE_BID_VIA_RTC) {
    // Place bid via RTC
    client.emit('ebidding:maxbid:*', {
      type: 'set_max_bid',
      ...params,
    });

    trackItemBid({ id: +params.clientItemId }, account);
    return;
  }

  // Place bid via API
  delete params.eventId;
  const response: EbidLotBidResponse = yield call(api.postEbidAutobid, params);

  if (response.ok && response.data) {
    const bids: ReturnType<typeof ItemSelectors.ebidItems> = yield select(ItemSelectors.ebidItems);

    if (!bids[itemId]) {
      _logger.info('Get ebid items while setAutobid');
      yield put(itemActions.getEbidItems([+itemId]));
    }
  }
}

export function* getBidActivity() {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account?.id) {
    // avoid 403 calls if user is not logged in
    _logger.warn('saga/ebid.getBidActivity without logged in?');
    return;
  }

  const response: EbidActivityResponse = yield call(api.getEbidActivity);
  if (response.ok && response.data) {
    yield put(itemActions.getBidActivitySuccess(response.data.data));
    // Sets Items Bidding
    const items = response.data.data;
    _logger.info('saga/ebid.getBidActivity', { items });
    const biddings: IItem.Bidding[] = [];
    const offers: { itemId: number; offer: Partial<IItem.Offer> }[] = [];

    Object.values(items).forEach((item) => {
      const itemId = +item.clientItemId;
      const composedItem = composeItemFromFlatResponse(item);
      // _logger.info('getBidActivity', { item });
      const hasBids = item.lastBid > 0;
      const isOffer = item.saleStatus === 'OFFER';
      const isUserWinner = !!(item.winnerId && +item.winnerId === account.id);
      composedItem.bidding = {
        isRunning: item.auctionStatus === 'RUNNING',
        winnerId: item.winnerId ? +item.winnerId : undefined,
        isWinning: hasBids && isUserWinner,
        isOutbid: hasBids && !isUserWinner,
        isOffer,
      };

      const status = getBiddingStatus(composedItem);

      // const status = getBiddingStatus(item, account);
      const bidding: IBidding.Bidding = {
        status,
        auctionStatus: item.auctionStatus,
        type: IBidding.TYPES.EBID,
        bidsCount: item.bidCount,
        currentBidAmount: item.currentBidAmt,
        lastBidAmount: item.lastBid,
        startTime: item.startTime,
        endTime: item.endTime,
        winnerId: item.winnerId ? +item.winnerId : undefined,
        isOfferWinner: isOffer && isUserWinner,
        ...setComputedValues(item),
        ...setComputedStatuses(status),
      };
      biddings.push({ itemId, bidding });

      if (item.saleStatus === 'OFFER') {
        offers.push({
          itemId,
          offer: {
            offerAmount: item.lastBid,
            saleEventId: +item.eventId,
            saleStatus: item.saleStatus,
          },
        });
      }
    });

    yield put(itemActions.setEbidItems(items));
    yield put(itemActions.setOffers(offers));
    yield put(itemActions.setBiddings(biddings));
    yield put(ebidActions.updateCounts());

    // TODO: What are we lacking from bid activity endpoint?
    // const ids = response.data.data?.map((item) => +item.clientItemId);
    // if (ids?.length) {
    //   yield put(ebidActions.getItems(ids));
    // }
  } else {
    yield put(itemActions.getBidActivityFailure('getBidActivity error'));
  }
}

function* updateCounts() {
  const items: ReturnType<typeof ItemSelectors.myEbidItems> = yield select(
    ItemSelectors.myEbidItems,
  );

  const debugData = items.map((item) => {
    return {
      id: item.id,
      stockNum: item.stockNum,
      bidding: item.bidding,
    };
  });
  _logger.info('saga/ebid.updateCounts', debugData);
  // Sets Winning/Outbid Counts
  const counts: IEbid.CountsMap = {
    winning: 0,
    outbid: 0,
  };
  if (items && Object.keys(items).length > 0) {
    Object.values(items).forEach((item) => {
      const saleEventId = item.saleEvent.id;
      if (item.bidding) {
        const winning = [
          IBidding.STATUS.WON,
          IBidding.STATUS.OFFER,
          IBidding.STATUS.WINNING,
        ].includes(item.bidding.status)
          ? 1
          : 0;

        const outbid = [IBidding.STATUS.OUTBID, IBidding.STATUS.LOST].includes(item.bidding.status)
          ? 1
          : 0;

        counts.winning += winning;
        counts.outbid += outbid;
        if (!counts[saleEventId]) {
          counts[saleEventId] = { winning: 0, outbid: 0 };
        }
        counts[saleEventId].winning += winning;
        counts[saleEventId].outbid += outbid;
      }
    });
  }
  yield put(ebidActions.setCounts(counts));
}

function* relayLiveEventStats(
  client: typeof Socket,
  action: ReturnType<typeof ebidActions.relayLiveEventStats>,
) {
  if (Config.canAttendLiveAuction()) {
    _logger.info('saga/ebid.relayLiveEventStats', action.payload);
    // entity name confused due to 'ebidding' is the only keyword recognize in RTC at the moment
    client.emit('ebidding:relay_stats.v1:LIVE', {
      msgType: 'relay_stats.v1',
      stats: action.payload,
    });
  }
}

export const setComputedStatuses = (status: IBidding.Status) => {
  const isWinning = status === IBidding.STATUS.WINNING;
  const isOutbid = status === IBidding.STATUS.OUTBID;
  const isLost = status === IBidding.STATUS.LOST;
  const isOffer = status === IBidding.STATUS.OFFER;
  const isWon = status === IBidding.STATUS.WON;

  return {
    hasBids: isWinning || isOutbid || isLost || isOffer || isWon,
    isOutbid,
    isWinning,
    isLost,
    isOffer,
    isWon,
  };
};

export const setComputedValues = (bid: {
  auctionStatus: string;
  maxAutobid?: number;
  lastBid?: number;
}) => ({
  maxBidInput: bid.maxAutobid || bid.lastBid || 0,
  isRunning: bid.auctionStatus === IEbid.AUCTION_STATUS.RUNNING,
  isEnded: bid.auctionStatus === IEbid.AUCTION_STATUS.ENDED,
  isStaged: bid.auctionStatus === IEbid.AUCTION_STATUS.STAGED,

  // TODO: We can set all computed values once we store input amount in redux state
  // canUpdateMaxBid: status === IBidding.STATUS.WINNING,
});

function* resetSendingState() {
  const items: ReturnType<typeof ItemSelectors.ebidItemsInSendingState> = yield select(
    ItemSelectors.ebidItemsInSendingState,
  );
  _logger.info('saga/ebid.resetSendingState');

  const biddings: IItem.Bidding[] = [];

  if (items?.length > 0) {
    items.forEach((item) => {
      const itemId = item.id;
      const bidding = {
        ...item.bidding,
        sendingState: {
          isSending: false,
          sendingSince: 0,
          hasExceededDuration: false,
        },
      };

      biddings.push({ itemId, bidding });
    });
  }

  yield put(itemActions.setBiddings(biddings));
}

function* updateSendingState() {
  const items: ReturnType<typeof ItemSelectors.ebidItemsWithinSendingDuration> = yield select(
    ItemSelectors.ebidItemsWithinSendingDuration,
  );

  const biddings: IItem.Bidding[] = [];
  if (items?.length > 0) {
    items.forEach((item) => {
      if (Date.now() - item?.bidding?.sendingState?.sendingSince > allowedSendingBidDuration) {
        const itemId = item.id;
        const bidding = {
          ...item.bidding,
          sendingState: {
            ...item.bidding.sendingState,
            hasExceededDuration: true,
          },
        };
        biddings.push({ itemId, bidding });
      }
    });
  }

  yield put(itemActions.setBiddings(biddings));
}

export default function* ebidAuctionSaga() {
  yield takeLatest(ebidActions.getIncrementConfig.type, getIncrementConfig);
  yield takeLatest(ebidActions.watchEbidAuctionSocket.type, watchEbidAuction);
  yield takeEvery(ebidActions.getBidHistory.type, getBidHistory);
  // TOCHECK getBidActivity
  yield takeLatest(itemActions.getBidActivity.type, getBidActivity);
  // TOCHECK calling e-bidding/v2/items?show_images=true many times
  yield takeLatest(itemActions.getEbidItems.type, getEbidItems);

  yield takeEvery(ebidActions.updateCounts.type, updateCounts);
  yield takeLatest(ebidActions.resetSendingState, resetSendingState);
  yield takeLatest(ebidActions.updateSendingState, updateSendingState);
}
